/*=========================================================================================
  File Name: moduleMedicineMutations.js
  Description: Medicine Module Mutations
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/

export default {
  ADD_ITEM(state, item) {
    state.Medicines.unshift(item);
  },
  SET_Medicine(state, Medicines) {
    state.Medicines = Medicines;
  },
  UPDATE_Medicine(state, Medicine) {
    const MedicineIndex = state.Medicines.findIndex(p => p.ID == Medicine.ID);
    Object.assign(state.Medicines[MedicineIndex], Medicine);
  },
  REMOVE_ITEM(state, itemId) {
    const ItemIndex = state.Medicines.findIndex(p => p.ID == itemId);
    state.Medicines.splice(ItemIndex, 1);
  }
};
